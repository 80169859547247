import React from "react"
import Link from "gatsby-link"
import Layout from "../../layouts/index"

export default () => (
  <Layout path="pd">
    <div className="pd-hero ism">
      <div className="content-wrap">
        <div className="pd-top-info ism">
          <div className="pd-name">
            <div className="pd-logo"></div>
            <div className="title">
              <h2>Luma服务管理</h2>
              <span>为客户提供更好的体验</span>
            </div>
          </div>
          <h4>您的服务和IT系统好坏是通过客户的满意度来评价的</h4>
          <p>
            因此您必须响应并准确地解决现有客服水准中遭遇的挑战。为了实现这些目标，您需要自动化来推进方案效果，您需要我们的智能服务管理解决方案，它涵盖全面的客服管理，包括IT服务管理(ITSM)。
          </p>
          <p>
            通过智能服务管理，您可以准确、及时地响应您的客户，并确保您的IT资源满足您的组织和最终用户的业务需求。
          </p>
        </div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <div className="pd-ism-sec1-detail">
          <div className="pd-ism-sec1-info">
            <h3>不要被低效的软件方案束缚</h3>
            <h4>这会拖慢你的速度，消耗你的资金</h4>
            <p>
              ISM具备随时交付、低IT投资、灵活性和敏捷性等特性，可以在没有专门的开发人员或管理员团队的情况下，发展您的客服体验，实现低成本和可预测，少花钱多办事。
            </p>
            <p>这样你就可以专注于取悦你的客户，实现你的商业目标。</p>
          </div>
        </div>
        <div className="pd-ism-spec">
          <h4>ISM的核心特点</h4>
          <ul>
            <li>冷启动接触预设</li>
            <li>低代码工作流程修订</li>
            <li>实现最佳的ITIL体验</li>
            <li>简易的个性化管理</li>
            <li>打通CRM系统查看用户信息</li>
            <li>管理IT资产</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="pd-section ism-solution" id="pd-ism-core">
      <div className="content-wrap">
        <h3>ServiceAI提供了一个强大的、功能齐全的客服管理解决方案</h3>
        <h5>
          我们九个认证的ITIL流程帮助您提供卓越的服务支持。
          <br />
          你还可以定制流程工作流以与业务系统集成，这比修改业务系统更加便捷高效。
        </h5>
        <div className="ism-funcs">
          <h4>ISM的核心功能</h4>
          <ul>
            <li>
              <Link to="/products/product-ism-core/#section-1">事件管理</Link>
              <span>快速响应，及时恢复业务</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-2">服务请求</Link>
              <span>提供标准一致的服务体验</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-3">问题管理</Link>
              <span>找出根源故障，解决问题</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-4">变更管理</Link>
              <span>受控的变更活动，协作和执行</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-5">知识管理</Link>
              <span>基于AI的知识管理，自动推荐解决方案</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-6">服务目录</Link>
              <span>服务标准化，一致的服务体验</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-7">配置管理</Link>
              <span>服务管理的核心，受信的配置管理系统</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-8">
                资产管理和自动发现
              </Link>
              <span>自动发现工具，全生命周期的资产管理</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-9">项目管理</Link>
              <span>敏捷管理，快速看板工具</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-10">
                无需编码的配置
              </Link>
              <span>无需编程的流程和表单定义</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-11">全局检索</Link>
              <span>基于ElasticSearch的全文检索，一处搜索，全局可见</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-12">集成</Link>
              <span>多种数据集成手段</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-13">多语言</Link>
              <span>支持11个语种，国际化产品体验</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-14">
                高级报告分析
              </Link>
              <span>可视化的报表自定义工具</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-15">远程支持</Link>
              <span>高效的远程支持工具</span>
            </li>
            <li>
              <Link to="/products/product-ism-core/#section-16">其它功能</Link>
              <span>通过微信、钉钉访问，已及更多实用功能</span>
            </li>
          </ul>
          <div className="btn-group">
            <Link
              to="/products/product-ism-core"
              className="btn btn-solid btn-blue"
            >
              了解更多
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="pd-section ism-more-func">
      <div className="content-wrap">
        <h4>其它特点</h4>
        <ul>
          <li>多通道接入</li>
          <li>服务水平协议(SLA)管理</li>
          <li>可用性管理</li>
          <li>身份和访问管理</li>
          <li>便笺本</li>
          <li>个性化服务体验</li>
        </ul>
      </div>
    </div>
  </Layout>
)
